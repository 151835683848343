.shine, .chrome {
	font-size: 3em;
	margin: 0 auto;
	margin-bottom: 1em;
	font-weight: bold;
}

.shine {
	background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
	-webkit-background-size: 150px;
	color: #16a09eb5;
	-webkit-background-clip: text;
	-webkit-animation-name: 'shine';
	-webkit-animation-duration: 5s;
	-webkit-animation-iteration-count: infinite;
	text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
    text-align: center;
}

.chrome {
	background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
	background-image: -webkit-linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%);
	-webkit-background-size: 200px;
	color: #16a09eb5;
	-webkit-background-clip: text;
	-webkit-animation-name: shine;
	-webkit-animation-duration: 5s;
	-webkit-animation-iteration-count: infinite;
	text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
}

@media only screen and (max-width: 600px) {
    .shine {
        background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
        -webkit-background-size: 150px;
        color: #16a09eb5;
        -webkit-background-clip: text;
        -webkit-animation-name: 'shine';
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
        text-align: center;
    }
    
    .chrome {
        background: #222 -webkit-gradient(linear, left top, right top, from(#222), to(#222), color-stop(0.5, #fff)) 0 0 no-repeat;
        background-image: -webkit-linear-gradient(-40deg, transparent 0%, transparent 40%, #fff 50%, transparent 60%, transparent 100%);
        -webkit-background-size: 200px;
        color: #16a09eb5;
        -webkit-background-clip: text;
        -webkit-animation-name: shine;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        text-shadow: 0 0px 0px rgba(255, 255, 255, 0.5);
    }
  }

@keyframes shine {
	0%, 10% {
		background-position: -1000px;
	}
	20% {
		background-position: top left;
	}
	90% {
		background-position: top right;
	}
	100% {
		background-position: 1000px;
	}
}

:root {
	--dark-green: #21A694;
	--click-green: #04aa6d;
	--light-green: #8BD987;
  }

  @keyframes paymentComplete {
	  0% {
		  transform: translateY(100%);
	  }

	  65% {
		  transform: translateY(100%);
	  }

	  70% {
		  transform: translateY(0%);
	  }

	  100% {
		transform: translateY(0%);
	}
  }

  #PmtComplete {
	transform: translateY(100%);
  }

  #DoneBtn {
	transform: translateY(100%);
  }

  #Tick {
	transform: translateY(100%);
  }

  /* #Ewallet {
	animation: wallet1Slide 10s infinite;
	-webkit-animation: wallet1Slide 10s infinite;
  } */

  @keyframes wallet1Slide {
	0% {
	  transform: translateX(0%);
	}

	10% {
	  transform: translateX(0%);
	}

	20% {
	  transform: translateX(-100%);
	}

	100% {
	  transform: translateX(-100%);
	}
  }

  #Ewallet2 {
	transform: translateX(100%);
  }

  @keyframes wallet2Slide {
	  0% {
		  transform: translateX(100%);
	  }

	  10% {
		  transform: translateX(100%);
	  }

	  20% {
		  transform: translateX(0%);
	  }

	  60% {
		  transform: translateX(0%);
	  }

	  65% {
		  transform: translateY(-100%);
	  }

	  100% {
		  transform: translateY(-100%);
	  }
  }

  @keyframes walletMobileSlide {
	0% {
		transform: translateX(0%);
	}

	60% {
		transform: translateX(0%);
	}

	65% {
		transform: translateY(-100%);
	}

	100% {
		transform: translateY(-100%);
	}
}

  #Rectangle4_2>rect {
	fill: var(--dark-green);
	/* animation: btnTap 10s infinite;
	-webkit-animation: btnTap 10s infinite; */
  }

  .landingTxnReport {
	display: none;
	margin-top: 7em;
  }

  @keyframes txnReportSlide {
	0% {
		transform: translate(0%,-100%);
	}

	100% {
	  transform: translate(0%,-100%);
	}
  }

  @keyframes btnTapMobile {
	0% {
	  fill: var(--dark-green);
	  transform: translateY(0%);
	}

	15% {
	  fill: var(--dark-green);
	  transform: scale(1) translateY(0%);
	}

	25% {
	  fill: var(--dark-green);
	  transform: scale(1.05) translateY(0%);
	  transform-origin: 50% 50%;
	}

	35% {
	  fill: var(--dark-green);
	  transform: scale(1) translateY(0%);
	}

	40% {
		fill: var(--dark-green);
		transform: translateY(0%);
	  }

	55% {
	  transform: translateY(-100%) !important;
	}

	100% {
	  transform: translateY(-100%) !important;
	}
  }

  @keyframes btnTap {
	0% {
	  fill: var(--dark-green);
	  transform: translateY(0%);
	}

	50% {
	  fill: var(--dark-green);
	  transform: scale(1);
	}

	55% {
	  fill: var(--dark-green);
	  transform: scale(1.1);
	  transform-origin: 50% 50%;
	}

	60% {
	  fill: var(--dark-green);
	  transform: scale(1);
	}

	60% {
		fill: var(--dark-green);
		transform: translateY(0%);
	  }

	65% {
	  transform: translateY(-100%);
	}

	100% {
	  transform: translateY(-100%);
	}
  }

  #Notification {
	transform: translateY(-100%);
  }

  @keyframes incomingNotification {
	  0% {
		  transform: translateY(-100%);
	  }

	  75% {
		  transform: translateY(-100%);
	  }

	  85% {
		  transform: translateY(0%);
	  }

	  100% {
		  transform: translateY(0%);
	  }
  }

  @keyframes slideCardInfo {
	  0% {
		  transform: translateY(0%);
	  }

	  60% {
		  transform: translateY(0%);
	  }

	  65% {
		  transform: translateY(-100%);
	  }

	  100% {
		  transform: translateY(-100%);
	  }
  }